import { createApp } from 'vue'
import App from './App.vue'
// router
import router from './router'

import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, md } from 'vuetify/iconsets/md'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md
        },
    },
    theme: {defaultTheme: 'dark'}

})

createApp(App).use(router).use(vuetify).mount('#app')
