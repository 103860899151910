<template>
  <v-app>
    <!-- <v-app-bar density="compact" :elevation="4">
      <template v-slot:prepend>
        <v-avatar :tile="true">
          <v-img 
          :src="require('@/assets/logo.png')" 
          alt="logo" 
          class="logo" />
        </v-avatar>
        <h4>Austin Ridings</h4>
      </template>
    </v-app-bar> -->
    <v-main class="bg-img">
      <v-container fluid class="text-center">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "app",
};
</script>

<style >
@import './assets/main.css';

.bg-img {
    background-image: linear-gradient(to top, silver, rgb(68, 80, 107));
}

.footer {
  text-align: center;
  color: #000;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' 200,
  'opsz' 48
}
</style>

